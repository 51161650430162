import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';

import PageContent from './components/Page';
import { Content, Layout } from './components/LayoutV2';
import { ApplyNowButton } from './components/Programme/ApplyNowButton';
import { HeroHeader } from './components/Programme/HeroHeader';
import { ProgrammeNavigation } from './components/Programme/Navigation';
import { ProgrammeBlogs } from './components/Programme/Blogs/ProgrammeBlogs';
import { StickyProgrammeNavigation } from './components/Programme/StickyNavigation';
import useSticky from './hooks/useSticky';
import { TypeFormPopUp } from './components/Layout/3rdParty/TypeFormPopUp';

interface Props {
  data: any;
  pageContext: any;
  location: any;
}

const Programme: FunctionComponent<Props> = ({
  data,
  pageContext,
  location,
}) => {
  const page = data.strapiPages;
  const programme = data.strapiProgrammes;
  const navigation = pageContext.navigation;

  const { blogs, homepage, roles, blogCategories, products, campPage } =
    pageContext;
  const title = [programme.Title];
  const pageTitle = page.Headline || page.Display_Title;

  if (!homepage) {
    title.push(pageTitle);
  }
  const metadata = page.Metadata || { Title: false };

  // SEO request: to hide pages from the navigation bar but maintain as direct subpage of programme in the url
  const navWithoutExceptions = navigation.filter(
    (nav: any) =>
      nav.title !== 'Itinerary' &&
      nav.title !== 'Blue Mountains Resort' &&
      nav.title !== 'Four Seasons Whistler'
  );

  const menu = navWithoutExceptions.map((nav: any, i: any) => ({
    text: nav.title,
    url: nav.to,
    current:
      (location.pathname === nav.to && i === 0) ||
      (location.pathname.startsWith(nav.to) && i > 0),
  }));

  let imageLink = undefined;

  if (programme.Title === 'Work Australia')
    imageLink = 'http://promo.www.bunac.org/jobfairaustralia/';
  if (programme.Title === 'Work New Zealand')
    imageLink = 'http://promo.www.bunac.org/jobfairnewzealand/';

  const { isSticky, element } = useSticky();

  const ctas = page.CTA.map((cta: any) => cta.Text);
  const urls = page.CTA.map((cta: any) => cta.URL);

  return (
    <Layout
      className="layout-v2"
      title={metadata.Title || title.join(' ')}
      desc={metadata.Description || ''}
      noIndex={metadata.noIndex}
    >
      <Content>
        <StickyProgrammeNavigation items={menu} visible={isSticky} />
        <HeroHeader
          title={page.Display_Title}
          subtitle={page.Headline}
          text={page.Introduction}
          ctas={ctas}
          urls={urls}
          image={page.Key_Visual}
          imageLink={imageLink}
          blogs={blogs && blogs.length > 0}
          locationPage={campPage === true}
          includeCurrencySelector={products && products.length > 0}
          includeCountrySelector={page.Display_Title.indexOf('Visa') >= 0}
          includeGetInTouch={
            page.Headline === '' ||
            page.Headline.includes('Visa') ||
            page.Headline.includes('Cost')
          }
        />
        {title[0] === 'Summer Camp USA' && <TypeFormPopUp />}
        <ApplyNowButton url={programme.CTA.URL} />
        <ProgrammeNavigation items={menu} element={element} />
        {blogs && <ProgrammeBlogs blogs={blogs} />}
        {page &&
          page.Content &&
          page.Content.map((content: any, index: number) => (
            <PageContent
              key={index}
              content={content}
              roles={roles}
              categories={blogCategories}
              products={products}
              layoutVersion={'V2'}
            />
          ))}
      </Content>
    </Layout>
  );
};

export const query = graphql`
  query ProgrammeV2Query($id: Int!, $pageId: Int!) {
    strapiProgrammes(strapiId: { eq: $id }) {
      strapiId
      Duration
      Title
      Theme
      Pipedrive_ID
      Category {
        id
      }
      Cost {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      Deposit {
        EUR
        GBP
        USD
        NZD
        AUD
      }
      CTA {
        Text
        URL
        Button
      }
      Key_Visual {
        publicURL
      }
      Eligibility {
        Title
      }
    }
    strapiPages(strapiId: { eq: $pageId }) {
      id
      Title
      Display_Title
      Headline
      Slug
      Introduction
      Metadata {
        id
        Title
        Description
      }
      Key_Visual {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [AUTO, WEBP])
        }
      }
      Cost {
        GBP
        EUR
        USD
        AUD
        NZD
      }
      Key_Visual {
        publicURL
      }
      CTA {
        Text
        URL
        Button
      }
      Content {
        id
        Title
        Subtitle
        Content
        ButtonText
        CTA_Text
        CTA_URL
        Colour
        IconLayout
        ImageLayout
        Introduction
        YouTube_URL
        URL
        Decoration
        ContentLayout
        ColorVariant
        Restriction
        Button {
          Text
          URL
        }
        Buttons {
          Text
          URL
        }
        SideBySideDisplay
        Travel_With_Confidence_Title
        Layout
        ReviewsTitle
        ReviewsSubtitle
        BackgroundType
        Brush {
          Stroke_Type
        }
        Key_Visual {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
        Galleries {
          Title
          id
        }
        JobRoles {
          Title
        }
        Questions {
          Answer
          Question
          id
        }
        Steps {
          Title
          Subtitle
          Content
          id
        }
        Locations {
          Camp
          Location
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
        Icons {
          Content
          Key_Visual {
            publicURL
          }
        }
        Quote_Tabs {
          Tab_Title
          Quotes {
            id
            Content
            Person
            Experience
          }
        }
        Blogs {
          id
          Title
          Intro
          Content
          Category
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
        }
        Quotes {
          id
          Content
          Person
          Experience
        }
        TextQuotes {
          id
          Content
          Person
          Experience
        }
        Grid_Items {
          Question
          Answer
        }
        Item1 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Item2 {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            id
            Text
            URL
          }
        }
        Product_One {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Product_Two {
          Title
          Subtitle
          Introduction
          Details
          First_Payment {
            Product_Title
            Product {
              id
            }
          }
          Desposit {
            Product_Title
            Product {
              id
            }
          }
          Second_Payment {
            Product_Title
            Product {
              id
            }
          }
          Final_Text
          Include_Total
          Button {
            Text
            URL
          }
        }
        Items {
          Content
          Title
          Subtitle
          Key_Visual {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
            }
          }
          Button {
            Text
            URL
          }
        }
      }
    }
  }
`;

export default Programme;
