import React, { FunctionComponent, ReactNode, useRef } from 'react';
import Carousel, { RenderPaginationProps } from 'react-elastic-carousel';

interface ArrowProps {
  active: boolean;
}

const NextArrow: FunctionComponent<ArrowProps> = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      id="Path_3983"
      data-name="Path 3983"
      d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z"
      fill={`${active ? '#5fbec2' : '#999'}`}
    />
    <path
      id="Arrow"
      d="M9.781,8.192,1.913.323a1.139,1.139,0,0,0-1.59,0,1.139,1.139,0,0,0,0,1.59L7.41,9,.323,16.087a1.139,1.139,0,0,0,0,1.59,1.139,1.139,0,0,0,1.59,0l7.868-7.9A1.139,1.139,0,0,0,9.781,8.192Z"
      transform="translate(15 11)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);

const PreviousArrow: FunctionComponent<ArrowProps> = ({ active }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      id="Path_3983"
      data-name="Path 3983"
      d="M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Z"
      fill={`${active ? '#5fbec2' : '#999'}`}
    />
    <path
      id="Arrow"
      d="M.323,8.192,5.507,3.008,8.192.323a1.139,1.139,0,0,1,1.59,0,1.139,1.139,0,0,1,0,1.59L4.549,7.145,2.695,9l7.087,7.087a1.124,1.124,0,0,1-1.59,1.59L5.684,15.16.323,9.781A1.139,1.139,0,0,1,.323,8.192Z"
      transform="translate(14.895 11)"
      fill="#fff"
      fill-rule="evenodd"
    />
  </svg>
);

interface PageProps {
  page: number;
  isActive: boolean;
  onClick: VoidFunction;
}

const PageDisplay: FunctionComponent<PageProps> = ({
  page,
  isActive,
  onClick,
}) => (
  <div
    className={`mr-2 ml-2 font-worksans text-base ${
      isActive ? 'text-blue' : ''
    }`}
    onClick={onClick}
  >
    {page + 1}
  </div>
);

interface NavigationProps extends RenderPaginationProps {
  carousel: any;
}

const renderPagination = ({
  pages,
  activePage,
  onClick,
  carousel,
}: NavigationProps) => {
  return (
    <div className="flex flex-row items-center">
      <button
        className="mr-2"
        onClick={() => carousel.slidePrev()}
        disabled={pages.indexOf(activePage) === 0}
      >
        <PreviousArrow active={pages.indexOf(activePage) !== 0} />
      </button>
      {pages.map((page) => {
        return (
          <PageDisplay
            key={page}
            page={page}
            isActive={activePage === page}
            onClick={() => onClick(`${page}`)}
          />
        );
      })}
      <button
        className="ml-2"
        onClick={() => carousel.slideNext()}
        disabled={pages.indexOf(activePage) === pages.length - 1}
      >
        <NextArrow active={pages.indexOf(activePage) !== pages.length - 1} />
      </button>
    </div>
  );
};

interface PaginationProps {
  itemsToShow?: number;
  children: ReactNode;
}

const Pagination: FunctionComponent<PaginationProps> = ({
  itemsToShow = 1,
  children,
}) => {
  const content = useRef(null);

  return (
    <Carousel
      ref={content}
      itemsToShow={itemsToShow}
      isRTL={false}
      transitionMs={300}
      renderArrow={() => <></>}
      renderPagination={({ pages, activePage, onClick }) =>
        renderPagination({
          pages,
          activePage,
          onClick,
          carousel: content.current,
        })
      }
    >
      {children}
    </Carousel>
  );
};

export { Pagination };
