import React, { FunctionComponent } from 'react';
import { BlogItem } from '@bunac/components/Programme/Blogs/Item';
import { BlogItemDataSource } from '@bunac/components/Page/datasource';
import formatSlug from '@bunac/lib/slugFormatter';
import { Paper } from '@bunac/components/Programme/Decoration/Paper';
import { groupItems } from '@bunac/lib/chunk';
import { Pagination } from '@bunac/components/Programme/Pagination';

interface ProgrammeBlogsProps {
  blogs: BlogItemDataSource[];
}

const PER_PAGE = 9;
const MAX_ITEMS = 50;

const BlogsPage: FunctionComponent<ProgrammeBlogsProps> = ({ blogs }) => (
  <div className="md:grid md:grid-cols-3 md:gap-8">
    {blogs.map((blog, i) => {
      const url = formatSlug([
        'blog',
        blog.Category ? blog.Category.Title : 'other',
        blog.Title,
      ]);

      return (
        <BlogItem
          key={i}
          title={blog.Title}
          introduction={blog.Intro}
          url={url}
          image={blog.Key_Visual}
          backgroundColor={'white'}
        />
      );
    })}
  </div>
);

const ProgrammeBlogs: FunctionComponent<ProgrammeBlogsProps> = ({ blogs }) => {
  const groups = groupItems(blogs.slice(0, MAX_ITEMS), PER_PAGE);

  return (
    <Paper>
      <div className="pb-12 px-4 md:m-auto md:max-w-5xl md:mt-8 md:mb-0">
        <Pagination>
          {groups.map((items: BlogItemDataSource[], i: number) => (
            <BlogsPage key={i} blogs={items} />
          ))}
        </Pagination>
      </div>
    </Paper>
  );
};

export { ProgrammeBlogs };
